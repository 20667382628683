<template>
  <div>
    <div class="ui-type-caption text-uppercase text-gray-600">
      {{ getClientsCount() }}
      {{
        $t(
          "role.orders.manage.ddmp.detail.delivery.table.products.table.orderCount"
        )
      }}
    </div>
    <span
      >{{ getClientsCount() }}
      {{
        $t(
          "role.orders.manage.ddmp.detail.delivery.table.products.table.clientCount"
        )
      }}</span
    >
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "ProductsTableOrders",

  data() {
    return {
      data: {},
    };
  },

  methods: {
    getOrderCount() {
      return get(this.data, "count_orders");
    },
    getClientsCount() {
      return get(this.data, "count_families");
    },
  },
};
</script>
